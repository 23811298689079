<template>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                        <div>
                            <h4 class="mb-3">Liste des types de repas</h4>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="table-responsive rounded mb-3">
                    <table id="example" class="data-table table mb-0 tbl-server-info">
                        <thead class="bg-white text-uppercase">
                            <tr class="ligth ligth-data">
                                <th>Title</th>
                                <th>Price</th>
                                <th>Take away</th>
                                <th>Start hour</th>
                                <th>End hour</th>
                                <th v-if="permissions.includes('show-meal-types')">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="ligth-body">
                            <tr v-for="(type,index) in types" :key="index">
                                <td>{{type.libelle}}</td>
                                <td>{{type.cout}} XOF</td> 
                                <td>{{type.cout_away}} XOF</td>
                                <td>{{type.hour_start}} </td> 
                                <td>{{type.hour_end}} </td>  
                                <td v-if="permissions.includes('show-meal-types')">
                                    <div class="d-flex align-items-center list-action">
                                        
                                        <router-link :to="'/show-type-repas/'+type.id" v-if="permissions.includes('show-meal-types')"
                                                    class="badge badge-info mr-2" data-toggle="tooltip" data-placement="top" 
                                                    title="" data-original-title="View"><i class="fa fa-eye mr-0"></i>
                                        </router-link>
                                        
                                        
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            <!-- Page end  -->
        </div>
        <!-- Modal Edit -->
        <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="true" 
                :is-full-page="fullPage"></loading>
            </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
//import store from '@/store'
import $ from "jquery"
export default {
    components: {
            Loading
    },
    data() {
        return {
            perms:  [],
            permissions: [],
            isLoading: false,
            fullPage: true,
            types: []
        };
    },
    mounted(){
        this.permissions = this.$store.state.perms.split('911')
        // console.log('p', this.perms)
        // this.perms.forEach(element => {
        //     this.permissions.push(element.name)
        // });
        this.getTypes()
        //console.log('perms',this.permissions);
    },
    methods:{
        getTypes(){
            let app = this
            app.isLoading = true
            axios.get(URL_API+'types-repas-admin')
            .then(function (reponse){
                console.log('reponse', reponse)
                if (reponse.data.status) {
                    $(document).ready( function () {
                            $('#example').DataTable(
                                {
                                    "lengthMenu": [ 5,10, 25, 50, 75, 100 ],
                                    "pageLength": 10,
                                    'order':[]
                                }
                            );
                        } );
                    app.types = reponse.data.data
                    app.isLoading = false
                }
                
            })
            .catch(function (error){
                console.log('error', error);
            })
        },
        destroy(id) {
            Swal.fire({
                title: 'Do you want to delete this Meal type ?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, deleted!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading =  true
                    axios.delete(URL_API+'types-repas/'+id)
                    .then(response => {
                        console.log(response)
                        if (response.data.status == true) {
                            this.isLoading =  false
                        Swal.fire(
                            'Deleted!',
                            'The Meal type  was deleted.',
                            'success'
                        )
                        
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                        this.isLoading =  false
                    })
                    
                }
            })
            
        },
    }
}
</script>