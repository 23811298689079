<template>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                        <div>
                            <h4 class="mb-3">Consumed tickets list</h4>

                        </div>
                        <div class="row">
                            From : <input class="form-control col-lg-4" @change="first" type="date" v-model="payload.from" name="" id="">
                            To : <input class="form-control col-lg-4" @change="second" type="date" v-model="payload.to" name="" id="">
                        </div>
                        <div>
                            <button @click="printPage()" class="btn mt-10 btn-primary mr-2">Print</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="table-responsive rounded mb-3" v-if="payload.from != '' && payload.to != ''">
                        <table id="tbl_exporttable_to_xls"  class="data-table table mb-0 tbl-server-info">
                            <thead class="bg-white text-uppercase">
                                <tr colspan="7"><h5>Students</h5></tr>
                                <tr class="ligth ligth-data">
                                    <th>Meal type </th>
                                    <th>Quantity</th>
                                    <th>Extras</th>
                                    <th>Takeaway</th>
                                    <th>Extras Amount</th>
                                    <th>Takeaway Amount</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody class="ligth-body">
                                <tr v-for="(bail,index) in bails" :key="index">
                                    <td>{{bail.libelle}}</td>
                                    <td>{{bail.qteTotal}}</td>
                                    <td>{{bail.xqteTotal}}</td>
                                    <td>{{bail.aqteTotal}}</td>
                                    <td>{{formatPrice(bail.xqteTotalAmount)}} XOF</td>
                                    <td>{{formatPrice(bail.aqteTotalAmount)}} XOF</td>
                                    <td>{{formatPrice(bail.coutTotal)}} XOF</td>  
                                </tr>
                                <tr v-if="bails.length == 0">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2"> Aucune donnée </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr v-else>
                                    <td colspan="2">Total amount</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ this.xcout }} XOF</td>
                                    <td>{{ this.acout }} XOF</td>
                                    <td> {{this.cout}} XOF</td>
                                </tr>
                            </tbody>
                        </table>
                        <button @click="ExportToExcel('xlsx')" class="btn mt-10 btn-primary mr-2">Export</button>
                        <br>
                        <br>
                        <br>
                        <table id="tbl_exporttable_to_xls1"  class="data-table table mb-0 tbl-server-info">
                            <thead class="bg-white text-uppercase">
                                <tr colspan="7"><h5>Staff</h5></tr>
                                <tr class="ligth ligth-data">
                                    <th>Meal type </th>
                                    <th>Quantity</th>
                                    <th>Extras</th>
                                    <th>Takeaway</th>
                                    <th>Extras Amount</th>
                                    <th>Takeaway Amount</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody class="ligth-body">
                                <tr v-for="(bail,index) in bails1" :key="index">
                                    <td>{{bail.libelle}}</td>
                                    <td>{{bail.qteTotal}}</td>
                                    <td>{{bail.xqteTotal}}</td>
                                    <td>{{bail.aqteTotal}}</td>
                                    <td>{{formatPrice(bail.xqteTotalAmount)}} XOF</td>
                                    <td>{{formatPrice(bail.aqteTotalAmount)}} XOF</td>
                                    <td>{{formatPrice(bail.coutTotal)}} XOF</td>  
                                </tr>
                                <tr v-if="bails.length == 0">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2"> Aucune donnée </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr v-else>
                                    <td colspan="2">Total amount</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ this.xcout1 }} XOF</td>
                                    <td>{{ this.acout1 }} XOF</td>
                                    <td> {{this.cout1}} XOF</td>
                                </tr>
                            </tbody>
                        </table>
                        <button @click="ExportToExcel1('xlsx')" class="btn mt-10 btn-primary mr-2">Export</button>
                        <br>
                        <br>
                        <br>
                        <table id="tbl_exporttable_to_xls2"  class="data-table table mb-0 tbl-server-info">
                            <thead class="bg-white text-uppercase">
                                <tr colspan="7"><h5>Events</h5></tr>
                                <tr class="ligth ligth-data">
                                    <th>Meal type </th>
                                    <th>Quantity</th>
                                    <th>Extras</th>
                                    <th>Takeaway</th>
                                    <th>Extras Amount</th>
                                    <th>Takeaway Amount</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody class="ligth-body">
                                <tr v-for="(bail,index) in bails2" :key="index">
                                    <td>{{bail.libelle}}</td>
                                    <td>{{bail.qteTotal}}</td>
                                    <td>{{bail.xqteTotal}}</td>
                                    <td>{{bail.aqteTotal}}</td>
                                    <td>{{formatPrice(bail.xqteTotalAmount)}} XOF</td>
                                    <td>{{formatPrice(bail.aqteTotalAmount)}} XOF</td>
                                    <td>{{formatPrice(bail.coutTotal)}} XOF</td>  
                                </tr>
                                <tr v-if="bails.length == 0">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2"> Aucune donnée </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr v-else>
                                    <td colspan="2">Total amount</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ this.xcout2 }} XOF</td>
                                    <td>{{ this.acout2 }} XOF</td>
                                    <td> {{this.cout2}} XOF</td>
                                </tr>
                            </tbody>
                        </table>
                        <button @click="ExportToExcel2('xlsx')" class="btn mt-10 btn-primary mr-2">Export</button>
                    </div>
                    <div class="table-responsive rounded mb-3" v-else>
                        <table id="tbl_exporttable_to_xls4"  class="data-table table mb-0 tbl-server-info">
                            <thead class="bg-white text-uppercase">
                                <tr class="ligth ligth-data">
                                    <th>Code</th>
                                    <th>Quantity</th>
                                    <th>Purchased by</th>
                                    <th>Valid until</th>
                                </tr>
                            </thead>
                            <tbody class="ligth-body">
                                <tr v-for="(bail,index) in tickets" :key="index">
                                    <td>{{bail.code}}</td>
                                    <td>{{bail.qte}}</td>
                                    <th v-if="bail.purchased_by">{{bail.purchased_by.nom}} {{bail.purchased_by.prenoms}} ({{bail.purchased_by.matricule}})</th>
                                    <th v-else></th>
                                    <td>{{bail.validite}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <button @click="ExportToExcel4('xlsx')" class="btn mt-10 btn-primary mr-2">Export</button>
                    </div>
                </div>
            </div>

            <!-- Page end  -->
        </div>
        <!-- Modal Edit -->
            <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="true" 
                :is-full-page="fullPage"></loading>
            </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
import $ from "jquery"
import moment from 'moment'
import store from '@/store'
import * as XLSX from 'xlsx/xlsx.mjs'
moment.locale('fr')
export default {
    components: {
            Loading
    },
    data() {
        return {permissions: [],
            tickets:{},
            bails:[],
            bails1:[],
            bails2:[],
            payload:{
                from: '',
                to: '',
                expired: 0,
                valid: 0,
            },
            cout: 0,
            xcout: 0,
            acout: 0,
            cout1: 0,
            xcout1: 0,
            acout1: 0,
            cout2: 0,
            xcout2: 0,
            acout2: 0,
            isLoading: false,
            fullPage: true,
            types: null,
            store: store
        };
    },
    mounted(){this.$store.state.user.permissions.forEach(element => {
            this.permissions.push(element.name)
        });
        this.getTickets('ticket-use-paye')
    },
    methods:{
        printPage() {
            setTimeout(function(){
                    window.print();
            }, 1000);
        },
        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getBails() {
            this.isLoading =  true
            axios.post(URL_API+'calcul-ticket', this.payload)
            .then(reponse => {
                this.isLoading = false
                console.log('bails', reponse)
                if (reponse.data.status == true) {
                    this.bails = reponse.data.data
                    this.cout = reponse.data.cout
                    this.xcout = reponse.data.xcout
                    this.acout = reponse.data.acout
                    if (this.bails.length == 0) {
                        Swal.fire('Note', 'No student ticket used in this period!', 'info')
                    }
                }
            })
            .catch(error => {
                this.isLoading = false
                console.log(error)
                this.errored = true
                
            })
            
        },
        getBails1() {
            this.isLoading =  true
            axios.post(URL_API+'calcul-ticket-staff', this.payload)
            .then(reponse => {
                this.isLoading = false
                console.log('bails', reponse)
                if (reponse.data.status == true) {
                    this.bails1 = reponse.data.data
                    this.cout1 = reponse.data.cout
                    this.xcout1 = reponse.data.xcout
                    this.acout1 = reponse.data.acout
                    if (this.bails1.length == 0) {
                        Swal.fire('Note', 'No staff ticket used in this period!', 'info')
                    }
                }
            })
            .catch(error => {
                this.isLoading = false
                console.log(error)
                this.errored = true
                
            })
            
        },
        getBails2() {
            this.isLoading =  true
            axios.post(URL_API+'calcul-ticket-event', this.payload)
            .then(reponse => {
                this.isLoading = false
                console.log('bails', reponse)
                if (reponse.data.status == true) {
                    this.bails2 = reponse.data.data
                    this.cout2 = reponse.data.cout
                    this.xcout2 = reponse.data.xcout
                    this.acout2 = reponse.data.acout
                    if (this.bails2.length == 0) {
                        Swal.fire('Note', 'No event ticket used in this period!', 'info')
                    }
                }
            })
            .catch(error => {
                this.isLoading = false
                console.log(error)
                this.errored = true
                
            })
            
        },
        first() {
            if (this.payload.from != '') {
                if (this.payload.to != '') {
                    this.getBails()
                    this.getBails1()
                    this.getBails2()
                } else {
                    Swal.fire('Note', 'Enter end date.', 'info')
                }
            } else {
                Swal.fire('Note', 'Enter begin date.', 'info')
            }
        },
        second() {
            if (this.payload.to != '') {
                if (this.payload.from != '') {
                    this.getBails()
                    this.getBails1()
                    this.getBails2()
                } else {
                    Swal.fire('Note', 'Enter begin date.', 'info')
                }
            } else {
                Swal.fire('Note', 'Enter end date.', 'info')
            }
        },
        getTickets(arg) {
            this.isLoading =  true
            axios.get(URL_API+arg)
            .then(response => {
                console.log(response)
                $(document).ready( function () {
                            $('#tbl_exporttable_to_xls4').DataTable(
                                {
                                    "lengthMenu": [ 5,10, 25, 50, 75, 100,500,1000 ],
                                    "pageLength": 10,
                                    'order':[],
                                    'buttons': [
                                    'copyHtml5',
                                    'excelHtml5',
                                    'csvHtml5',
                                    'pdfHtml5'
                                    ]
                                }
                            );
                        } );
                        response.data.data.forEach(element => {
                    element.created_at = moment(element.created_at).format('DD/MM/YYYY')
                });
                this.tickets = response.data.data
                this.isLoading =  false
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                Swal.fire(
                        'Error!',
                        'An error occured while fetching datas',
                        'error'
                )
                this.isLoading =  false
            })
        },
        ExportToExcel(type, fn, dl) {
            var elt = document.getElementById('tbl_exporttable_to_xls');
            var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
            return dl ?
                XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
                XLSX.writeFile(wb, fn || ('Sells sheet - '+ this.payload.from +'-'+ this.payload.to +'.' + (type || 'xlsx')));
        },
        ExportToExcel1(type, fn, dl) {
            var elt = document.getElementById('tbl_exporttable_to_xls1');
            var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
            return dl ?
                XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
                XLSX.writeFile(wb, fn || ('Sells sheet - '+ this.payload.from +'-'+ this.payload.to +'.' + (type || 'xlsx')));
        },
        ExportToExcel2(type, fn, dl) {
            var elt = document.getElementById('tbl_exporttable_to_xls2');
            var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
            return dl ?
                XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
                XLSX.writeFile(wb, fn || ('Sells sheet - '+ this.payload.from +'-'+ this.payload.to +'.' + (type || 'xlsx')));
        },
        ExportToExcel4(type, fn, dl) {
            var elt = document.getElementById('tbl_exporttable_to_xls4');
            var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
            return dl ?
                XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
                XLSX.writeFile(wb, fn || ('Sells sheet - '+ this.payload.from +'-'+ this.payload.to +'.' + (type || 'xlsx')));
        },
        destroy(id) {
            Swal.fire({
                title: 'Do you want to delete this ticket ?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, deleted!'
            }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading =  true
                axios.delete(URL_API+'ticket-use-paye/'+id)
                .then(response => {
                    console.log(response.data)
                    this.tickets = response.data
                    this.isLoading =  false
                    Swal.fire(
                        'Deleted!',
                        'The ticket was deleted.',
                        'success'
                    )
                    location.reload()
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                    this.isLoading =  false
                })
                
            }
            })
            
        },
    }
}
</script>