<template>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                        <div>
                            <h4 class="mb-3">top ups</h4>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <select name="" v-model="packSelected" class="form-control" id="" @change="getUsers(packSelected)">
                                <option v-for="(pack,index) in packs" :key="index" :value="pack.id">{{pack.libelle}}</option>
                            </select>
                        </div>
                        <div class="col-md-6" v-if="packSelected">
                            <button type="btn" class="btn btn-secondary" @click="creditAccount(packSelected)"> Credit the accounts </button>
                        </div>
                    </div>
                    <br>
                </div>
                <div class="col-lg-12">
                    <div class="table-responsive rounded mb-3">
                    <table id="example" class="data-table table mb-0 tbl-server-info">
                        <thead class="bg-white text-uppercase">
                            <tr class="ligth ligth-data">
                                <th>#</th>
                                <th>Student</th>
                                <!-- <th>Amount</th> -->
                                <th>Status</th>
                                <th>activation date</th>
                            </tr>
                        </thead>
                        <tbody class="ligth-body">
                            <tr v-for="(recharge,index) in recharge" :key="index">
                                <td >{{ index +1 }}</td>
                                <td >{{ recharge.nom +' '+recharge.prenoms }}</td>
                                <td  v-if="recharge.status == 1"> In residence </td>   
                                <td v-else> Off campus </td>
                                <td >{{ recharge.created_at }}</td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            <!-- Page end  -->
        </div>
        <!-- Modal Edit -->
        <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="true" 
                :is-full-page="fullPage"></loading>
            </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
//import $ from "jquery"
import moment from 'moment'
moment.locale('fr')

export default {
    components: {
            Loading
    },
    data() {
        return {
            permissions: [],
            recharge:[],
            isLoading: false,
            fullPage: true,
            packSelected:null,
            packs: [],
        };
    },
    mounted(){
        this.$store.state.user.permissions.forEach(element => {
            this.permissions.push(element.name)
        });
        // this.getRecharge()
        this.getPacks()
    },
    methods:{
        getRecharge(){
            let app = this
            app.isLoading = true
            axios.get(URL_API+'recharge')
            .then(function (reponse){
                console.log(reponse);
                if (reponse.data.status == true) {
                    app.recharge = reponse.data.data
                    app.recharge.forEach(element => {
                        element.created_at = moment(element.created_at).format('DD-MM-YYYY H:m')
                    });
                }
                
            })
            .catch(function (error){
                console.log(error);
            })
            app.isLoading = false
        },
        destroy(id) {
            Swal.fire({
                title: 'Do you want to delete this credit ?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, deleted!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading =  true
                    axios.delete(URL_API+'recharge/'+id)
                    .then(response => {
                        console.log(response.data)
                        this.isLoading =  false
                        Swal.fire(
                            'Deleted!',
                            'The credit was deleted.',
                            'success'
                        )
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                        this.isLoading =  false
                    })
                    
                }
            })
            
        },
        getPacks(){
            let app = this
            axios.get(URL_API+'packs')
            .then(function (reponse){
                console.log(reponse);
                if (reponse.data.status == true) {
                    app.packs = reponse.data.data
                }
            })
            .then(function (error){
                console.log(error);
            })
        },
        getUsers(packId){
            let app = this
            console.log(packId)
            axios.get(URL_API+'getpacks-users/'+packId)
            .then(function (reponse){
                console.log('ddsds',reponse);
                if (reponse.data.status == true) {
                    console.log('ofkfls',reponse.data.data)
                    app.recharge = reponse.data.data
                    app.recharge.forEach(element => {
                        element.created_at = moment(element.created_at).format('DD-MM-YYYY H:m')
                    });
                }
            })
            .then(function (error){
                console.log(error);
            })
        },
        creditAccount(packId){
            Swal.fire({
                title: 'Do you want to credit these accounts ?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, credit!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let app = this
                    console.log(packId)
                    axios.post(URL_API+'credit-resident-account',{
                        packId:packId
                    })
                    .then(function (reponse){
                        console.log('ddsds',reponse);
                        if (reponse.data.status == true) {
                            Swal.fire(
                                    'Succesful!',
                                    'account successfully credited',
                                    'success'
                            )
                            app.getUsers(packId)
                        }else{
                            Swal.fire(
                                    'Error!',
                                    reponse.data.data.message,
                                    'error'
                            )
                        }
                    })
                    .then(function (error){
                        console.log(error);
                    })
                    
                }
            })
            
        },
    }
}
</script>