<template>
    <div class="content-page">
        <div class="container-fluid add-form-list">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                        <div>
                            <h4 class="mb-3">Search user by code</h4>
                            
                        </div>
                        
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="card">
                        <form  @submit.prevent="getUser">
                            <div class="col-lg-6">
                                Card number :  <input class="form-control" @keyup="time()" v-model="payload.code" type="password" name="" id="code">
                                <br>
                            </div>
                        </form>
                    </div>
                    <div class="vld-parent">
                        <loading :active.sync="isLoading" 
                        :can-cancel="true" 
                        :is-full-page="fullPage"></loading>
                    </div>
                </div>
            </div>
            <!-- Page end  -->
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
//import store  from '@/store'
export default {
    components: {
            Loading
    },
    data() {
        return {
            payload: {
                code: ''
            },
            fullPage: true,
            isLoading: false,
            timer:null
        };
    },
    mounted(){
        document.getElementById('code').focus()
    },
    methods:{
        time() {

            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                this.getUser()
            }, 300)
        },
        getUser() {
            console.log('ddd')
            this.isLoading = true
            
            axios.post(URL_API+'verif-card', this.payload)
            .then(response => {
                console.log(response.data)
                if (response.data.status == true) {
                    //this.user = response.data.data
                    this.$router.push('/show-user/' + response.data.data.id)
                    /* response.data.data.permissions.forEach(element => {
                        this.perms.push(element.name)
                    }); */
                
                }
                else {
                    Swal.fire('...', response.data.message, 'warning')
                }
                this.isLoading = false
                this.payload.code = ''
            })
            .catch(error => {
                Swal.fire('error', 'An error occurred while retrieving data', 'error')
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
        },
        /* getPermissions() {
            this.isLoading = true
            
            axios.get(URL_API+'getPermissions')
            .then(response => {
                console.log(response.data)
                if (response.data.status == true) {
                    this.initials = response.data.data
                console.log('initials', this.initials);
                this.isLoading = false
                }
            })
            .catch(error => {
                Swal.fire('Echec de récupération', 'Une erreur s\'est produite lors de la récupération des données', 'error')
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
        } */
    }
}
</script>