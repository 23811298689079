<template>
  <div class="content-page">
      <div class="container-fluid add-form-list">
          <div class="row">
              <div class="col-sm-12">
                  <div class="card">
                      <div class="card-header d-flex justify-content-between">
                          <div class="header-title">
                              <h4 class="card-title">Save a caterer ticket</h4>
                          </div>
                      </div>
                      <div class="card-body">
                          <form @submit.prevent="store" data-toggle="validator">
                              <div class="row">
                                   
                                  <div class="col-md-2">
                                      <div class="form-group">
                                          <label>Caterer *</label>
                                          <input v-model="payload.caterer" id="code" type="text" class="form-control"  required>
                                          <div class="help-block with-errors"></div>
                                      </div>
                                  </div>  
                                  <div class="col-md-2">
                                      <div class="form-group">
                                          <label>Activity *</label>
                                          <input v-model="payload.activity" type="text" class="form-control"  required>
                                          <div class="help-block with-errors"></div>
                                      </div>
                                  </div> 
                                  <div class="col-md-2">
                                      <div class="form-group">
                                          <label>Amount *</label>
                                          <input v-model="payload.amount" type="number" class="form-control"  required>
                                          <div class="help-block with-errors"></div>
                                      </div>
                                  </div>  
                                  <div class="col-md-2">
                                      <div class="form-group">
                                          <label>User *</label>
                                          <select class="form-control" name="" v-model="payload.user_id" id="">
                                              <option v-for="(user, index) in users" :key="index" :value="user.id">{{ user.nom + " " + user.prenoms }}</option>
                                          </select>
                                          <div class="help-block with-errors"></div>
                                      </div>
                                  </div>        
                                  <div class="col-md-2">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary mr-2">Save</button>
                                    </div>
                                  </div>                          
                              </div>                        
                          </form>
                          
                          <div class="col-md-12">
                              <div class="table-responsive rounded mb-3">
                                  <table id="tbl_exporttable_to_xls" class="data-table table mb-0 tbl-server-info">
                                      <thead class="bg-white text-uppercase">
                                          <tr class="ligth ligth-data">
                                              <th>Code</th>
                                              <th>Amount</th>
                                              <th>Caterer</th>
                                              <th>Activity</th>
                                              <th>Organized By</th>
                                              <th>Status</th>
                                          </tr>
                                      </thead>
                                      <tbody class="ligth-body">
                                          <tr v-for="(stock,index) in tickets" :key="index">
                                              <td>{{stock.code}}</td>
                                              <td> {{ stock.amount }} </td>
                                              <td> {{ stock.caterer }} </td>
                                              <td> {{ stock.activity }} </td>
                                              <td> {{ stock.by_user.nom + " " + stock.by_user.prenoms }} </td>
                                              <td v-if="stock.status == 1"> Used </td>  
                                              <td v-else>Not Used</td>                                             
                                          </tr>
                                      </tbody>
                                  </table>
                                      <div>
                                          &nbsp;
                                      </div>
                                  <button v-if="perms.includes('export-tickets')" class="btn mt-10 btn-primary mr-2" @click="ExportToExcel('xlsx')">Export</button>
                              </div>
                          </div>
                          
              </div>
          </div>
                  <div class="vld-parent">
                      <loading :active.sync="isLoading" 
                      :can-cancel="true" 
                      :is-full-page="fullPage"></loading>
                  </div>
              </div>
          </div>
          <!-- Page end  -->
      </div>
  </div>
</template>
<script>
import * as XLSX from 'xlsx/xlsx.mjs'
import axios from "axios";
import {URL_API} from '@/config'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
import $ from "jquery"
import moment from 'moment'
moment.locale('fr')
export default {
  components: {
          Loading
  },
  data() {
      return {
          perms: [],
          isLoading: false,
          fullPage: true,
          tickets: {},
          users: [],
          cout:0,
          payload:{
              user_id: 0,
          },
      };
  },
  mounted(){this.$store.state.user.permissions.forEach(element => {
          this.perms.push(element.name)
      });
      console.log('test',this.payload)
      document.getElementById("code").focus();
      this.getTickets();
      this.getUsers();
  },
  methods:{

      store() {
          this.isLoading = true
          axios.post(URL_API+'create-cateticket', this.payload)
          .then(response => {
              console.log(response.data)
              this.isLoading = false
              
              if (response.data.status == true) {
                  Swal.fire(
                      response.data.message,
                       '',
                      'success'
                  )
                  document.getElementById("code").focus();
                  this.getTickets()
              }
              else{
                  Swal.fire(
                      
                      response.data.message ,
                      '',
                      'error'
                  )
                  this.product.code = ''
                  document.getElementById("code").focus();
              }
          })
          .catch(error => {
              console.log(error)
              this.errored = true
              this.isLoading = false
          })
      },
     getTickets(){
         axios.get(URL_API+'tikets-caterer')
         .then(response => {
              console.log(response.data)
              this.isLoading = false
              
              if (response.data.status == true) {
                  $(document).ready( function () {
                          $('#tbl_exporttable_to_xls').DataTable(
                              {
                                  "lengthMenu": [ 5,10, 25, 50, 75, 100,500 ],
                                  "pageLength": 10,
                                  'order':[],
                                  stateSave: true,
                                  "bDestroy": true
                              }
                          );
                      } );
                  this.tickets = response.data.data
              }
              
          })
          .catch(error => {
              console.log(error)
              this.errored = true
              this.isLoading = false
          })
     },

     getUsers(){
         axios.get(URL_API+'users')
         .then(response => {
              console.log(response.data)
              if (response.data.status == true) {
                response.data.data.forEach((element) => {
                    if (element.function == "Staff" && element.matricule != 0) this.users.push(element);
                });
              }
          })
          .catch(error => {
              console.log(error)
              this.errored = true
              this.isLoading = false
          })
     },

     ExportToExcel(type, fn, dl) {
     var elt = document.getElementById('tbl_exporttable_to_xls');
     var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
     return dl ?
       XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
       XLSX.writeFile(wb, fn || ('Sells sheet - '+ new Date() +'.' + (type || 'xlsx')));
  },
  }
}
</script>
